@import "variabls";
// Overlay Mixin
@mixin overlay($bg: $black, $alpha: 0.5) {
  background-color: rgba($bg, $alpha);
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}


.header-area {
  padding: 20px 0;

  &.sticky {
    background-color: $white;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
    transition: 0.3s;

    .logo {
      img {
        &.logo-light {
          display: none;
        }
      }
    }
  }

  &:not(.sticky) {
    .logo {
      img {
        &.logo-dark {
          display: none;
        }
      }
    }

    .btn-outline {
      border-color: $white;
      color: $white;

      &:hover {
        background-color: $white;
        color: #292929;
      }
    }
  }

  .btn-outline {
    font-size: 15px;
    @media #{$large-mobile} {
      padding: 15px 20px;
    }
  }
}

.hero-section {
  height: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &:before {
    @include overlay(#000000, 0.6);
  }
}

.hero-content {
  color: $white;

  h1 {
    color: $white;
    font-weight: 500;
    font-size: 36px;

    @media #{$large-mobile} {
      font-size: 24px;
      line-height: 1.3;
    }

    strong {
      font-size: 50px;
      font-weight: 700;
      display: block;
      margin-bottom: 30px;
    }
  }

  h4{
    color: #ffffff;
  }
}

.section-title {
  margin-bottom: 30px;
  text-align: center;

  h2 {
    font-weight: 500;
  }
}

.btn-outline {
  border: 2px solid #292929;
  border-radius: 100px;
  color: #151515;
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
  padding: 15px 40px;

  &:hover {
    background-color: #292929;
    color: #ffffff;
  }
}

.demo-item {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  text-align: center;
  transition: 0.4s;
  margin-top: 20px;

  .image {
    position: relative;
    overflow: hidden;
    display: block;
    max-height: 440px;

    &:before {
      @include overlay(#292929, 0.8);
      opacity: 0;
      transition: 0.4s;
    }

    i {
      border: 2px solid #ffffff;
      border-radius: 50%;
      color: #fff;
      font-size: 20px;
      opacity: 0;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: all 0.3s ease 0s;
      line-height: 46px;
      height: 50px;
      width: 50px;
      z-index: 9;

      &:hover {
        background-color: $white;
        color: #292929;
      }
    }
  }

  .title {
    margin-top: 0;
    padding: 20px 10px;
    font-weight: 500;

    a {
      color: #292929;
      font-size: 16px;
      text-transform: capitalize;
    }
  }

  &:hover:not(.coming) {
    transform: translateY(-10px);

    .image {
      &:before, i {
        opacity: 1;
      }
    }
  }

  &.coming {
    pointer-events: none;
  }
}

.feature-item {
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 45px 15px;
  text-align: center;
  margin-top: 30px;
  min-height: 205px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h4 {
    font-size: 14px;
    font-weight: 600;
    margin: 10px 0 0;
    text-transform: uppercase;
  }
}

.footer-content {
  padding: 100px 0;

  h3 {
    color: $white;
  }

  .btn-outline {
    border-color: $white;
    color: $white;
    font-size: 15px;
    margin-top: 20px;

    &:hover {
      background-color: $white;
      color: #292929;
    }
  }
}

.dark-version {
  .bg-dark {
    background-color: #333333 !important;
  }

  .header-area {
    &.sticky {
      background-color: #000000;

      .logo {
        img {
          &.logo-light {
            display: block;
          }

          &.logo-dark {
            display: none;
          }
        }
      }

      .btn-outline {
        border-color: #ffffff;
        background-color: #ffffff;

        &:hover {
          color: #333333;
        }
      }
    }
  }

  .feature-item {
    background-color: #333333;
  }

  .demo-item {
    .title {
      a {
        color: #efefef;
      }
    }
  }
}